import React, { useState, useEffect, useRef } from 'react';
import styles from './Chatbox.module.css';
import { FaLinkedin, FaEnvelope, FaWhatsapp, FaTimes } from 'react-icons/fa';
import ikerAvatar from './iker-avatar.png'; // Replace with actual avatar image

const Chatbox = () => {
    const [chatOpen, setChatOpen] = useState(false);
    const [chatHistory, setChatHistory] = useState([
        { sender: "bot", text: "👋 Hi! I'm Iker. How can I assist you today?" }
    ]);
    const [options, setOptions] = useState([
        { text: "I'm a recruiter with open positions", next: "recruiter" },
        { text: "Just exploring his work", next: "portfolio" }
    ]);
    const [botTyping, setBotTyping] = useState(false);
    const [inputField, setInputField] = useState(null);
    const [userResponses, setUserResponses] = useState({ company: "", position: "" });
    const chatEndRef = useRef(null);

    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatHistory, botTyping]);

    const responses = {
        recruiter: [{ sender: "bot", text: "🔹 Great! Could you tell me the **company name** you're hiring for?" }],
        recruiterPosition: [{ sender: "bot", text: "🔹 Got it! And what **position(s)** are you looking to fill?" }],
        recruiterFinal: [
            { sender: "bot", text: "🚀 Thanks for sharing! You can contact me through:" },
            { sender: "bot", text: "👇 Select an option below 👇" },
            { sender: "bot", text: "Do you need anything else?" }
        ],
        portfolio: [
            { sender: "bot", text: "🔹 Feel free to check out my experience. Let me know if you'd like to connect!" }
        ]
    };

    const handleOptionClick = (next) => {
        setChatHistory((prev) => [
            ...prev,
            { sender: "user", text: options.find(o => o.next === next).text }
        ]);
        setBotTyping(true);

        setTimeout(() => {
            setChatHistory((prev) => [...prev, ...responses[next]]);
            setBotTyping(false);
            if (next === "recruiter") {
                setInputField("company");
            } else {
                setOptions([]);
            }
        }, 1000);
    };

    const handleUserInput = (value) => {
        if (inputField === "company") {
            setUserResponses((prev) => ({ ...prev, company: value }));
            setChatHistory((prev) => [...prev, { sender: "user", text: value }]);
            setBotTyping(true);

            setTimeout(() => {
                setChatHistory((prev) => [...prev, ...responses.recruiterPosition]);
                setBotTyping(false);
                setInputField("position");
            }, 1000);
        } else if (inputField === "position") {
            setUserResponses((prev) => ({ ...prev, position: value }));
            setChatHistory((prev) => [...prev, { sender: "user", text: value }]);
            setBotTyping(true);

            setTimeout(() => {
                setChatHistory((prev) => [...prev, ...responses.recruiterFinal]);
                setBotTyping(false);
                setInputField(null);
                setOptions([]);
                console.log("Recruiter Info Saved:", userResponses);
            }, 1000);
        }
    };

    return (
        <div>
            {/* Floating Contact Button */}
            <div className={styles.chatButton} onClick={() => setChatOpen(true)}>
                📩 Contact Iker
            </div>

            {/* Full-Screen Futuristic Chat Popup */}
            {chatOpen && (
                <div className={styles.chatOverlay}>
                    <div className={styles.chatboxContainer}>
                        <div className={styles.chatHeader}>
                            <img src={ikerAvatar} alt="Iker Avatar" className={styles.avatar} />
                            <span>Chat with Iker</span>
                            <FaTimes className={styles.closeButton} onClick={() => setChatOpen(false)} />
                        </div>

                        <div className={styles.chatbox}>
                            {chatHistory.map((msg, index) => (
                                <div key={index} className={msg.sender === "bot" ? styles.botMessage : styles.userMessage}>
                                    {msg.text}
                                </div>
                            ))}
                            {botTyping && <div className={styles.botTyping}>⏳ Typing...</div>}
                            <div ref={chatEndRef}></div>
                        </div>

                        <div className={styles.options}>
                            {inputField ? (
                                <input
                                    type="text"
                                    className={styles.inputField}
                                    placeholder={inputField === "company" ? "Enter company name..." : "Enter position(s)..."}
                                    onKeyDown={(e) => e.key === "Enter" && handleUserInput(e.target.value)}
                                />
                            ) : (
                                options.map((option, index) => (
                                    <button key={index} onClick={() => handleOptionClick(option.next)} className={styles.optionButton}>
                                        {option.text}
                                    </button>
                                ))
                            )}
                        </div>

                        {/* Contact Options */}
                        {chatHistory.some(msg => msg.text.includes("Select an option below")) && (
                            <div className={styles.contactOptions}>
                                <a href="mailto:contact@ikerordonana.com" className={styles.contactLink}>
                                    <FaEnvelope size={18} /> Email Iker
                                </a>
                                <a href="https://www.linkedin.com/in/ikerordo%C3%B1ana/" target="_blank" rel="noopener noreferrer" className={styles.contactLink}>
                                    <FaLinkedin size={18} /> LinkedIn
                                </a>
                                <a href="https://wa.me/34600406225" target="_blank" rel="noopener noreferrer" className={styles.contactLink}>
                                    <FaWhatsapp size={18} /> WhatsApp
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chatbox;
