import React from 'react';
import TimelineItem from './TimelineItem';
import styles from './ProfessionalTimeline.module.css';

// Import logos
import ataInsightsLogo from './CompanyLogos/AtaRenewables.png';
import googleLogo from './CompanyLogos/GoogleLogo.png';
import locktonLogo from './CompanyLogos/LocktonLogo.png';
import marshLogo from './CompanyLogos/MarshLogo.png';

const ProfessionalTimeline = () => {
    const timelineData = [
        {
            id: 1,
            title: "Business Development Manager",
            company: "ATA Insights",
            years: "Aug 2023 – Present",
            description: "Led business expansion efforts in the renewable energy sector, focusing on H2, Energy Storage, and Biomethane. Negotiated and closed high-value commercial agreements, driving a 15% revenue increase within six months.",
            companyLogo: ataInsightsLogo,
            companyUrl: "https://atainsights.com"
        },
        {
            id: 2,
            title: "Google Workspace Support Services Manager",
            company: "Google Spain",
            years: "Aug 2022 – Oct 2022",
            description: "Managed a high-value AAA client portfolio, fostering direct relationships with C-level executives. Maintained a 100% client retention rate through exceptional service.",
            companyLogo: googleLogo,
            companyUrl: "https://workspace.google.com/solutions/enterprise/"
        },
        {
            id: 3,
            title: "Sub Manager of New Business",
            company: "Lockton",
            years: "Jun 2021 – May 2022",
            description: "Led B2B new business development, identifying and executing strategic market expansion opportunities. Managed and grew a portfolio of high-value clients with a 100% retention rate.",
            companyLogo: locktonLogo,
            companyUrl: "https://lockton.com"
        },
        {
            id: 4,
            title: "Senior Account Executive",
            company: "Marsh",
            years: "Apr 2019 – May 2021",
            description: "Managed and expanded a client portfolio focused on international tech companies. Led end-to-end sales processes, including client prospecting, data analysis, and strategic consulting.",
            companyLogo: marshLogo,
            companyUrl: "https://marsh.com"
        }
    ];

    return (
        <div className={styles.timeline}>
            {timelineData.map(item => (
                <TimelineItem key={item.id} data={item} />
            ))}
        </div>
    );
};

export default ProfessionalTimeline;
