import React from 'react';
import styles from './Certifications.module.css';

// List of certifications
const certifications = [
    {
        name: "Google Digital Marketing & E-commerce Professional Certificate",
        issuer: "Google & Coursera",
        year: "Issued 2023",
        certificateUrl: "https://www.coursera.org/professional-certificates/google-digital-marketing-ecommerce" // Replace with actual certificate URL if available
    }
];

const Certifications = () => {
    return (
        <div className={styles.certificationsContainer}>
            <h2>Certifications</h2>
            <div className={styles.certificationsList}>
                {certifications.map((cert, index) => (
                    <div key={index} className={styles.certificationItem}>
                        <h3>{cert.name}</h3>
                        <p><strong>Issued by:</strong> {cert.issuer}</p>
                        <p><strong>Year:</strong> {cert.year}</p>
                        {cert.certificateUrl && (
                            <a href={cert.certificateUrl} target="_blank" rel="noopener noreferrer">
                                View Certificate
                            </a>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Certifications;
