import React from 'react';
import Header from './components/common/Header'; // Correct path for Header
import Home from './components/sections/Home/Home';
import ProfessionalTimeline from './components/sections/ProfessionalTimeline/ProfessionalTimeline';
import EducationLanguages from './components/sections/EducationLanguages/EducationLanguages';
import Certifications from './components/sections/Certifications/Certifications';
import TechnicalSkills from './components/sections/TechnicalSkills/TechnicalSkills';
import FAQ from './components/sections/FAQ/FAQ';
import Chatbox from './components/sections/Contact/Chatbox';
import Footer from './components/common/Footer'; // Correct path for Footer

function App() {
  return (
    <div>
      <Header /> {/* Header is at the top of the page */}
      <Home />
      <ProfessionalTimeline />
      <EducationLanguages />
      <Certifications />
      <TechnicalSkills />
      <FAQ />
      <Chatbox />
      <Footer /> {/* Footer is at the bottom */}
    </div>
  );
}

export default App;
