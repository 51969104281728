import React from 'react';
import styles from './ProfessionalTimeline.module.css';

const TimelineItem = ({ data }) => {
    return (
        <div className={styles.timelineItem}>
            <h3>{data.title} @ <a href={data.companyUrl} target="_blank" rel="noopener noreferrer">{data.company}</a></h3>
            <p className={styles.years}>{data.years}</p>
            <p>{data.description}</p>
            <img src={data.companyLogo} alt={`${data.company} logo`} className={styles.logo} />
        </div>
    );
};

export default TimelineItem;
