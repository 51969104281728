import React from 'react';
import styles from './Header.module.css';

const Header = ({ openChat }) => {
    return (
        <header className={styles.header}>
            <div className={styles.logo}>Iker Ordoñana</div>
            <nav className={styles.nav}>
                <a href="#home">Home</a>
                <a href="#professional">Professional Trajectory</a>
                <a href="#sports">Sports Trajectory</a>
                <button className={styles.contactButton} onClick={openChat}>Contact</button>
            </nav>
        </header>
    );
};

export default Header;
