import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <nav className={styles.footerLinks}>
                    <a href="#home">Home</a>
                    <a href="#timeline">Professional Timeline</a>
                    <a href="#contact">Contact</a>
                </nav>
            </div>

            <div className={styles.footerBottom}>
                <p>© {new Date().getFullYear()} Iker Ordoñana. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
