import React from 'react';
import styles from './EducationLanguages.module.css';

const EducationLanguages = () => {
    const educationData = [
        {
            degree: "Master in Business Administration (MBA)",
            school: "Universidad Católica de Murcia (UCAM), Spain",
            years: "2022 – 2023"
        },
        {
            degree: "Bachelor in Commerce & International Business",
            school: "Banking and Commercial School (EBC), Mexico",
            years: "2014 – 2018"
        }
    ];

    const languageSkills = [
        { language: "Spanish", level: "Native" },
        { language: "English", level: "Proficient" },
        { language: "French", level: "Intermediate" }
    ];

    return (
        <div className={styles.container}>
            <div className={styles.educationSection}>
                <h2>Education</h2>
                {educationData.map((edu, index) => (
                    <div key={index} className={styles.educationItem}>
                        <h3>{edu.degree}</h3>
                        <p>{edu.school}</p>
                        <p>{edu.years}</p>
                    </div>
                ))}
            </div>
            <div className={styles.languagesSection}>
                <h2>Languages</h2>
                {languageSkills.map((lang, index) => (
                    <div key={index} className={styles.languageItem}>
                        <h3>{lang.language}</h3>
                        <p>{lang.level}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EducationLanguages;
