import React, { useState } from 'react';
import styles from './FAQ.module.css';

// List of FAQs with video responses
const faqData = [
    {
        question: "What motivates you in your professional career?",
        videoUrl: "/videos/motivation.mp4" // Replace with actual path
    },
    {
        question: "What are your main strengths in business development?",
        videoUrl: "/videos/strengths.mp4"
    },
    {
        question: "How do you approach strategic partnerships?",
        videoUrl: "/videos/strategic_partnerships.mp4"
    },
    {
        question: "Why did you transition into renewable energy?",
        videoUrl: "/videos/renewable_energy.mp4"
    },
    {
        question: "What are your long-term career goals?",
        videoUrl: "/videos/career_goals.mp4"
    }
];

const FAQ = () => {
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    return (
        <div className={styles.faqContainer}>
            <h2>Most Frequently Asked Questions</h2>
            <div className={styles.faqList}>
                {faqData.map((faq, index) => (
                    <div key={index} className={styles.faqItem}>
                        <button onClick={() => setSelectedQuestion(index)}>
                            {faq.question}
                        </button>
                        {selectedQuestion === index && (
                            <div className={styles.videoContainer}>
                                <video controls>
                                    <source src={faq.videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
