import React from 'react';
import styles from './TechnicalSkills.module.css';

// List of technical skills categorized
const technicalSkills = {
    "Programming": ["JavaScript (React)", "Python (Basic)"],
    "CRM & Sales Tools": ["Salesforce", "SAP", "ERP", "Active Campaign"],
    "Digital Marketing & Data Analysis": ["Google Workspace", "WordPress", "Canva"],
    "Business & Market Analysis": ["Market Research", "Business Intelligence"],
    "Renewable Energy": ["Strategic Partnerships", "B2B Development", "Industry Expertise"]
};

const TechnicalSkills = () => {
    return (
        <div className={styles.skillsContainer}>
            <h2>Technical Skills</h2>
            <div className={styles.skillsGrid}>
                {Object.entries(technicalSkills).map(([category, skills], index) => (
                    <div key={index} className={styles.skillCategory}>
                        <h3>{category}</h3>
                        <ul>
                            {skills.map((skill, skillIndex) => (
                                <li key={skillIndex}>{skill}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TechnicalSkills;
