import React from 'react';
import styles from './Home.module.css';  // Ensure the CSS file exists and is correctly named

function Home() {
    return (
        <div className={styles.home}>
            <div className={styles.videoContainer}>
                <video src="/path-to-your-video.mp4" controls className={styles.video}>
                    Sorry, your browser does not support embedded videos.
                </video>
            </div>
            <div className={styles.summary}>
                <h1>Welcome to My Portfolio</h1>
                <p>I am a driven professional specializing in business development within the renewable energy sector. Explore my site to learn more about my professional journey and skills.</p>
            </div>
        </div>
    );
}

export default Home;
